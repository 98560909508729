const EveningData =[
    {
        index:1,
        e_slot_time: "05:00 PM",
          color : ["green","white"],
    },
    {
        index:2,
        e_slot_time: "05:30 PM",
          color : ["green","white"],
    },
    {
        index:3,
        e_slot_time: "06:00 PM",
          color : ["green","white"],
    },
    {
        index:4,
        e_slot_time: "06:15 PM",
          color : ["green","white"],
    },
    {
        index:5,
        e_slot_time: "06:30 PM",
          color : ["green","white"],
    },
    {
        index:6,
        e_slot_time: "06:45 PM",
          color : ["green","white"],
    },
    {
        index:7,
        e_slot_time: "07:00 PM",
          color : ["green","white"],
    },
    {
        index:8,
        e_slot_time: "07:15 PM",
          color : ["green","white"],
    },
    {
        index:9,
        e_slot_time: "07:30 PM",
          color : ["green","white"],
    },
    {
        index:10,
        e_slot_time: "07:45 PM",
          color : ["green","white"],
    },
    {
        index:11,
        e_slot_time: "08:00 PM",
          color : ["green","white"],
    },
    {
        index:12,
        e_slot_time: "08:15 PM",
          color : ["green","white"],
    },
    {
        index:13,
        e_slot_time: "08:30 PM",
          color : ["green","white"],
    },
    {
        index:14,
        e_slot_time: "08:45 PM",
          color : ["green","white"],
    },
];

export default EveningData;